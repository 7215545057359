<template>
  <div>
    <a-modal
      v-model="visibleModalManageLembur"
      class="modal-lembur"
      :title="isRejected ? 'Berikan Alasan' : 'Detil Pengajuan'"
      @cancel="toggleModalManageLembur"
      centered
      closable
    >
      <a-row :gutter="16">
        <a-col :span="24" v-if="isRejected">
          <a-textarea size="large" v-model="detilLembur.keterangan_admin" allow-clear :auto-size="{minRows: 12}" />
        </a-col>
        <template v-else>
          <a-col :span="24">
            <a-form-item label="Nama Pegawai">
              <a-input size="large" v-model="detilLembur.nama" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Waktu Awal">
                <a-date-picker
                  disabled
                  :value="detilLembur.waktu_awal"
                  format="DD MMMM YYYY, HH:mm"
                  class="w-100"
                  size="large"
                  placeholder=""
                  />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Waktu Akhir">
                <a-date-picker
                  disabled
                  :value="detilLembur.waktu_akhir"
                  format="DD MMMM YYYY, HH:mm"
                  class="w-100"
                  size="large"
                  placeholder=""
                  />
            </a-form-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-item label="Status Pengajuan">
              <a-select class="w-100" size="large" v-model="detilLembur.status_pengajuan" :disabled="!isRejected">
                <a-select-option value="menunggu">Menunggu</a-select-option>
                <a-select-option value="tolak">Ditolak</a-select-option>
                <a-select-option value="terima">Diterima</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :span="24">
            <a-form-item label="Keterangan Tambahan">
              <a-textarea size="large" v-model="detilLembur.keterangan_tambahan" disabled :auto-size="{minRows: 12}"/>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="File Tambahan">
              <div class="rounded border">
                <template v-if="detilLembur.file_tambahan.length">
                  <div v-for="item in detilLembur.file_tambahan" :key="item.id" class="d-flex item">
                    <div class="ml-3">
                      <a-icon class="text-warning" type="file" style="font-size:20px;" />
                    </div>
                    <div class="ml-3">
                      <p>{{item.originalname}}</p>
                    </div>
                    <div class="ml-auto mr-3">
                      <a target="_blank" :href="`${config.apiUrl}/${item.filepath}`"><p class="text-primary text-uppercase">Download</p></a>
                    </div>
                  </div>
                </template>
              <template v-else>
                  <div style="d-flex justify-content-center align-items-center">
                    <a-empty class="p-4" />
                  </div>
              </template>
              </div>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
      <template slot="footer">
        <a-button :class="isRejected ? '' : 'd-none'" size="large" key="back" @click="toggleModalManageLembur">
          Batal
        </a-button>
        <a-button :class="isRejected ? '' : 'd-none'" size="large" key="submit" type="primary" :loading="loadingActionModalManageLembur" @click.prevent="handleOkModalManageLembur('tolak')">
          Ubah
        </a-button>
      </template>
    </a-modal>
      <div class="mt-4">
        <a-table
          :columns="lemburColumns"
          :data-source="lemburDataTable"
          :pagination="pagination"
          :loading="lemburLoadingTable"
          @change="handleTableChange"
          bordered
        >
          <div slot="aksi" slot-scope="value, record" class="d-flex flex-column">
            <a-button
            @click.prevent="toggleModalManageLembur('detil', record)"
            class="mr-3 text-warning border border-warning w-100"
            size="default"
          >
            <a-icon type="idcard"/>Detil
          </a-button>
            <a-button
            @click.prevent="toggleModalManageLembur('terima', record)"
            class="mr-3 text-info border border-info w-100 my-3"
            :disabled="record.status_pengajuan === 'terima'"
            size="default"
          >
            <a-icon type="check"/>{{record.status_pengajuan === 'terima' ? 'Disetujui' : 'Setuju'}}
          </a-button>
          <a-button
            @click.prevent="toggleModalManageLembur('tolak', record)"
            class="text-danger border border-danger w-100"
            size="default"
          >
            <a-icon type="close" />{{record.status_pengajuan === 'terima' ? 'Batalkan' : 'Tolak'}}
          </a-button>
            <!-- <a-button @click.prevent="handleDeletePeriode(record)" class="font-size-14 text-danger border border-danger" size="large">
              <a-icon type="delete" />Hapus
            </a-button> -->
          </div>
          <span slot="status" slot-scope="text">
            <a-tag :color="textStatus[text].color">
              {{textStatus[text].text.toUpperCase()}}
            </a-tag>
          </span>
        </a-table>
      </div>
  </div>
</template>

<script>
import moment from 'moment'
// const formPeriode = () => import('@/components/app/AdminHR/PeriodeKehadiran/FormPeriodeKehadiran')

const lemburColumns = [
  {
    title: 'Pemohon',
    dataIndex: 'nama',
    key: 'nama',
    // scopedSlots: { customRender: 'bulan' },
  },
  {
    title: 'Waktu Lembur',
    dataIndex: 'waktu',
    key: 'waktu',
    children: [
      {
        title: 'Mulai',
        dataIndex: 'waktu_awal',
        key: 'waktu_awal',
        width: 200,
        align: 'center',
        // scopedSlots: { customRender: 'waktu_awal' },
      },
      {
        title: 'Selesai',
        dataIndex: 'waktu_akhir',
        key: 'waktu_akhir',
        width: 200,
        align: 'center',
        // scopedSlots: { customRender: 'waktu_akhir' },
      },
    ],
  },
  {
    title: 'Status',
    dataIndex: 'status_pengajuan',
    key: 'status_pengajuan',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  // {
  //   title: 'Keterangan',
  //   dataIndex: 'keterangan_tambahan',
  //   key: 'keterangan_tambahan',
  //   align: 'center',
  // },
  {
    title: 'Aksi',
    dataIndex: 'aksi',
    key: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
export default {
  name: 'PeriodeKehadiran',
  components: {
    // formPeriode,
  },
  data() {
    return {
      lemburColumns,
      visibleModalManageLembur: false,
      loadingActionModalManageLembur: false,
      loadingDeletePeriode: false,
      lemburLoadingTable: false,
      lemburDataTable: [],
      isRejected: false,
      detilLembur: {
        nama: null,
        waktu_awal: null,
        waktu_akhir: null,
        keterangan_tambahan: null,
        file_tambahan: [],
        keterangan_admin: null,
        status_pengajuan: null,
      },
      idEdit: null,
      textStatus: {
        terima: {
          text: 'Diterima',
          color: 'green',
        },
        menunggu: {
          text: 'Menunggu',
          color: 'orange',
        },
      },
      pagination: {},
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchDataTable({
        page: pagination.current,
        order: order,
      })
    },
    handleChange(payload) {
      const { value, column } = payload

      this.detilLembur[column] = value
    },
    toggleModalManageLembur(status, data) {
      const timeOutNull = () => {
        this.visibleModalManageLembur = false
        return setTimeout(() => {
          this.isRejected = false
          this.detilLembur = {
            nama: null,
            waktu_awal: null,
            waktu_akhir: null,
            keterangan_tambahan: null,
            file_tambahan: null,
            keterangan_admin: null,
            status_pengajuan: null,
          }
          this.idEdit = null
        }, 350)
      }

      if (status === 'detil') {
        this.visibleModalManageLembur = true
        this.detilLembur = {
          ...data,
          waktu_awal: moment(data.waktu_awal, 'DD MMMM YYYY, HH:mm').locale('id'),
          waktu_akhir: moment(data.waktu_akhir, 'DD MMMM YYYY, HH:mm').locale('id'),
        }
      } else if (status === 'terima') {
        this.idEdit = data.id
        this.detilLembur.nama = data.nama
        this.handleOkModalManageLembur(status, data.nama)
      } else if (status === 'tolak') {
        this.idEdit = data.id
        this.detilLembur.nama = data.nama
        this.isRejected = true
        this.visibleModalManageLembur = !this.visibleModalManageLembur
        // this.handleOkModalManageLembur(status, data.nama)
      } else {
        timeOutNull()
      }

      // if (status) {
      //   if (status === 'edit') {
      //     this.isRejected = true
      //     this.idEdit = data.key
      //   }
      //   // }
      //   // this.status[status === 'add' ? 'isCreate' : 'isRejected'] = true
      // }
    },
    periodeIsExist() {
      this.fetchDataTable()
      const isExist = Boolean(this.lemburDataTable.find(periode =>
        periode.tahun === parseInt(this.detilLembur.tahun) && periode.bulan.toLowerCase() === this.detilLembur.bulan.toLowerCase(),
      ))
      return isExist
    },
    handleOkModalManageLembur(status) {
      const statusText = status === 'terima' ? 'menyetujui' : 'menolak'
      const nama = this.detilLembur.nama
      const content = `Apakah anda yakin ingin ${statusText} pengajuan lembur ${nama}?`
      this.$confirm({
        title: 'Peringatan',
        content: (
          <div>
            <div>{content}</div>
            <div><b>Perubahan ini akan mempengaruhi sistem absensi pegawai</b></div>
          </div>
        ),
        onOk: () => {
          this.loadingActionModalManageLembur = true
          const payload = {
            id: this.idEdit,
            dataLembur: {
              keterangan_admin: this.detilLembur.keterangan_admin,
              status_pengajuan: status,
            },
          }
          this.$store.dispatch('adminHR/EDIT_PENGAJUAN_PEGAWAI', payload)
            .then(isSuccess => {
              this.lemburLoadingTable = true
              this.toggleModalManageLembur()
              this.fetchDataTable()
              if (isSuccess) {
                const statusText = status === 'terima' ? 'disetujui' : 'ditolak'
                this.$notification.success({
                  message: 'Berhasil',
                  description: `Data Lembur ${nama} berhasil di ${statusText}`,
                })
              } else {
                const description = 'Data lembur gagal diubah'
                this.$notification.error({
                  message: 'Gagal',
                  description,
                })
              }
              this.loadingActionModalManageLembur = false
            })
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: this.isRejected ? 'Ubah Sekarang' : 'Tambah Sekarang',
        cancelText: 'Batal',
      })
    },
    fetchDataTable(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.lemburLoadingTable = true
      this.$store.dispatch('adminHR/FETCH_ALL_PENGAJUAN_LEMBUR', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(({ data, total }) => {
          // console.log(res)
          this.lemburLoadingTable = false
          const pagination = { ...this.pagination }
          pagination.total = total
          this.pagination = pagination
          // console.log(res)
          this.lemburDataTable = data.map(el => {
            return {
              key: el.id,
              id: el.id,
              nama: el.guru.nama,
              waktu_awal: moment(el.waktu_awal).locale('id').format('DD MMMM YYYY, HH:mm'),
              waktu_akhir: moment(el.waktu_akhir).locale('id').format('DD MMMM YYYY, HH:mm'),
              status_pengajuan: el.status_pengajuan,
              keterangan_tambahan: el.keterangan_tambahan,
              keterangan_admin: el.keterangan_admin,
              file_tambahan: el.files,
              id_pegawai: el.id_pegawai,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataTable()
  },
  computed: {
  },
}
</script>

<style>
</style>
